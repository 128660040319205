import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

const App = (props) => {
  return (
    <>
      <Helmet>
        <title>Root</title>
      </Helmet>
      <Outlet />
    </>
  )
}

export default App;
